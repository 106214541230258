import React , {useState,useEffect} from 'react'
import { Card } from "react-bootstrap";
import axios from 'axios';
import Modal from "react-modal";
import Swal from "sweetalert2";
// import Table from 'react-bootstrap/Table';
import "../../css/Postblog.css"
const download=require("../images/upload.png");
const supprimer = require("../images/supprimer.png");
const modifier= require("../images/update.png");



export const Post1 = () => {
	const [newBlog, setNewBlog] = useState({ description: "", des: "",  image_name:"" });
    const [isOpenAddBlog, setIsOpenAddBlog] = useState(false);
    const [isOpenUpdateBlog, setIsOpenUpdateBlog] = useState(false);
    const [current, setCurrent] = useState({});
    const [imageUrl, setImageUrl] = useState(null);
    const [notChange, setNotChange] = useState(true);

	function toggleAddBlog() {
		setIsOpenAddBlog(!isOpenAddBlog);
	  }
    function toggleUpdateBlog() {
      setIsOpenUpdateBlog(!isOpenUpdateBlog);
      if (isOpenUpdateBlog) {
        setImageUrl(null);
        setNotChange(true);
      }
    }
	useEffect(() => {
        fetchBlog();
      }, []);
	const [blog,setBlog]=useState([])
	const fetchBlog = async () => {
        try {
          const response = await fetch('https://precvadijoal-fadhiouth.sn/list-blog'); 
          const data = await response.json();
          console.log(data);
          setBlog(data);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      };
	  const handleAddBlog = async (e) => {
        e.preventDefault();
		const formData = new FormData();
		formData.append('description', newBlog.description);
		formData.append('des', newBlog.des);
		formData.append('image_name', newBlog.image_name);
    
        try {
          const response = await axios.post('https://precvadijoal-fadhiouth.sn/addblog', formData,{
			headers: {
			  'Content-Type': 'multipart/form-data',
			},
		  });
		
         // console.log(response.data);
          fetchBlog();
        //   const createdUser = response.data;
        //   setUsers([...users, createdUser])
          setNewBlog({ description: " ", des:" ",image_name: " " });
          setIsOpenAddBlog(!isOpenAddBlog);
        //   setIsSubmitted(true);
          // Handle successful login, e.g., store token in local storage or state
          Swal.fire({
            title: "Reussie !",
            text: "L'élément a été Ajouté.",
            icon: "success",
            timer: 3000, // La modal de succès se fermera automatiquement après 3 secondes (ajustez selon vos besoins)
            timerProgressBar: true, // Afficher une barre de progression pour le compte à rebours
          });
        } catch (error) {
          console.error(error);
        //   setErrorMessages({ name: "uname", message: errors.uname });`
          // Handle error, e.g., display error message to the user
        
        }
      };
	  const handleDeleteUser =async(userId)=> { 
      Swal.fire({
        title: "Êtes-vous sûr de vouloir supprimer cet élément ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
        
      }).then( async (result) =>  {
        if (result.isConfirmed) {
          try {
            await axios.delete(`https://precvadijoal-fadhiouth.sn/deleteblog/${userId}`);
			fetchBlog();
            // setUsers(users.filter(user => user.id !== userId));

            
          } catch (error) {
            console.error('Error deleting user:', error);
          }
  
          // Afficher une alerte de succès après la suppression (vous pouvez personnaliser cela)
          Swal.fire({
            title: "Supprimé !",
            text: "L'élément a été supprimé.",
            icon: "success",
            timer: 3000, // La modal de succès se fermera automatiquement après 3 secondes (ajustez selon vos besoins)
            timerProgressBar: true, // Afficher une barre de progression pour le compte à rebours
          });
        }
      });
        
      }
  /************************************************************************************************
   ********************************************PREVISUALISER L IMAGE ******************************
   **************************************************************************************************/
   const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setNotChange(false);
      setCurrent({ ...current, image_name: file });
      // Utilisation de FileReader pour lire le fichier en tant que données base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;

        // Stockage de l'URL dans l'état imageUrl
        setImageUrl(base64String);
      };

      // Lecture du fichier en tant que données base64
      reader.readAsDataURL(file);
    }
  };
  /************************************************************************************************
   ********************************************MODIFICATION ******************************
   **************************************************************************************************/
  const updateBloc = (e) => {
    e.preventDefault();
    //l'API modification ici
    console.log("api");
    Swal.fire({
      title: "Erreur !",
      text: "Cette fonctionnalité n'est pas encore disponible",
      icon: "error",
      timer: 3000, // La modal de succès se fermera automatiquement après 3 secondes (ajustez selon vos besoins)
      timerProgressBar: true, // Afficher une barre de progression pour le compte à rebours
    });
  };
	return (
		<div>
      <div className="contient" style={{width:"97%"}}>
        <button onClick={toggleAddBlog} className="ajout-ressource">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
          </svg>
        </button>
        <p>
          Ajouter un blog
        </p>
      </div>
               <Modal size="lg" isOpen={isOpenAddBlog}
        onRequestClose={toggleAddBlog} contentLabel="Ajouter Utilisateur "
        className="mymodal"
        ariaHideApp={false}
        overlayClassName="myoverlay"
        closeTimeoutMS={500} >          
 
               <form className="addForm" onSubmit={handleAddBlog}> 
                <div id="form-group"> 
                <label id="name-label"> Titre Blog </label> 
                <input required  type="text" name="description" placeholder="Entrez le titre du blog" value={newBlog.description} onChange={(e) => setNewBlog({ ...newBlog, description: e.target.value })}/>
                  </div>
                  <div id="form-group"> 
                  <label  id="name-label"> Description Blog </label> 
                <input className="textbox_input"  required type="text"  name="des"  placeholder="Description blog" value={newBlog.des} onChange={(e) => setNewBlog({ ...newBlog, des: e.target.value })}/>
                </div>
                <div id="form-group"> 
                <label  id="name-label"> Image Blog </label> 
                <input  required type="file" accept="image/*"  name="image_name" placeholder="Image Blog" onChange={(e) => setNewBlog({ ...newBlog, image_name: e.target.files[0] })}/>
                </div>
                <div id="form-group"> 
                <button type="submit"  >Ajouter</button>
                <button className='deletebutton' onClick={toggleAddBlog}>Annuler</button>
                </div>
                

            </form>
               </Modal>
                {/******MODALE MODIFICATION****** */}

      <Modal
        size="lg"
        isOpen={isOpenUpdateBlog}
        onRequestClose={toggleUpdateBlog}
        contentLabel="Modification"
        className="mymodal"
        ariaHideApp={false}
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="modalModif">
          {imageUrl && <img height={200} width={340} src={imageUrl} alt="Preview" />}
          {notChange && (
            <img
              alt="preview"
              width={340}
              height={300}
              src={"https://www.precvadi.sn/" + current.image_name}
            />
          )}
          <form className="addForm" onSubmit={updateBloc}>
            <div id="form-group">
              <label id="name-label"> Titre Blog </label>
              <input
                type="text"
                name="description"
                placeholder="Entrez le titre du blog"
                value={current.description}
                onChange={(e) =>
                  setCurrent({ ...current, description: e.target.value })
                }
              />
            </div>
            <div id="form-group">
              <label id="name-label"> Description Blog </label>
              <input
                className="textbox_input"
                type="text"
                name="des"
                placeholder="Description blog"
                value={current.des}
                onChange={(e) =>
                  setCurrent({ ...current, des: e.target.value })
                }
              />
            </div>
            <div id="form-group">
              <label id="name-label"> Image Blog </label>
              <input
                type="file"
                onChange={handleImageChange}
                accept="image/*"
                name="image_name"
                placeholder="Image Blog"
              />
            </div>
            <div id="form-group">
              <button
              type="submit"
             
              >
                Modifier
              </button>
              <span className="deletebutton_"  onClick={toggleUpdateBlog}>
                Annuler
              </span>
            </div>
          </form>
        </div>
      </Modal>

 <div className="card_contain">
        {blog.map((myblog) => (
          <div>
            <Card className="card_">
              <Card.Img
                className="card_img"
                variant="top"
                src={"https://precvadijoal-fadhiouth.sn/" + myblog.image_name}
                width={20}
                height={250}
              />
              <Card.Body className="card_body">
                <Card.Title className="card_title">
                  {myblog.description}
                </Card.Title>
                <Card.Text className="card_text">{myblog.des}</Card.Text>

                <div className="card_btn">
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={() => {
                      toggleUpdateBlog();
                      setCurrent(myblog);
                    }}
                  >
                    Modifier
                  </button>
                  <button
                    onClick={() => handleDeleteUser(myblog.id)}
                    type="button"
                    class="btn btn-danger"
                  >
                    Supprimer
                  </button>
                </div>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
	</div>
	);
};



export const PostRessource = () => {
	const [newBlog, setNewBlog] = useState({ description: "", des: "",  fileto:"" });
    const [isOpenAddBlog, setIsOpenAddBlog] = useState(false);
    const [isOpenUpdateBlog, setIsOpenUpdateBlog] = useState(false);
    const [current, setCurrent] = useState({});

   const  DownloadRessource = async(fileurl)=> {
      console.log ("download file ");
      const downloadLink = document.createElement('a');
      const originalString = fileurl;
      const substringToDelete = ""
      const filecsv= originalString.replace(substringToDelete, "");
      downloadLink.href = fileurl;
      console.log(filecsv);
      downloadLink.href = "https://precvadijoal-fadhiouth.sn/"+filecsv
      downloadLink.download = 'form_data.csv';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink); 

    }
	function toggleAddBlog() {
		setIsOpenAddBlog(!isOpenAddBlog);
	  }
    function toggleUpdateBlog() {
      setIsOpenUpdateBlog(!isOpenUpdateBlog);
    }
	useEffect(() => {
        fetchBlog();
      }, []);
	const [blog,setBlog]=useState([])
	const fetchBlog = async () => {
        try {
          const response = await fetch('https://precvadijoal-fadhiouth.sn/list-ressources'); 
          const data = await response.json();
          console.log("liste ressources")
          console.log(data);
          setBlog(data);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      };
	  const handleAddBlog = async (e) => {
        e.preventDefault();
		const formData = new FormData();
		formData.append('description', newBlog.description);
		formData.append('des', newBlog.des);
		formData.append('fileto', newBlog.fileto);
    console.log(newBlog.description);
    
        try {
          const response = await axios.post('https://precvadijoal-fadhiouth.sn/addressource', formData,{
			headers: {
			  'Content-Type': 'multipart/form-data',
			},
		  });
		
          console.log(response.data);
          fetchBlog();
        //   const createdUser = response.data;
        //   setUsers([...users, createdUser])
          setNewBlog({ description: " ", des:" ",fileto: " " });
          setIsOpenAddBlog(!isOpenAddBlog);
        //   setIsSubmitted(true);
        // Handle successful login, e.g., store token in local storage or state
          Swal.fire({
            title: "Reussie !",
            text: "L'élément a été Ajouté.",
            icon: "success",
            timer: 3000, // La modal de succès se fermera automatiquement après 3 secondes (ajustez selon vos besoins)
            timerProgressBar: true, // Afficher une barre de progression pour le compte à rebours
          });
        } catch (error) {
          console.error(error);
        //   setErrorMessages({ name: "uname", message: errors.uname });`
          // Handle error, e.g., display error message to the user
        }
      };
	  const handleDeleteUser =async(userId)=> { 
      Swal.fire({
        title: "Êtes-vous sûr de vouloir supprimer cet élément ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
        
      }).then( async (result) =>  {
        if (result.isConfirmed) {
          try {
            await axios.delete(`https://precvadijoal-fadhiouth.sn/deleteressource/${userId}`);
			fetchBlog();
            // setUsers(users.filter(user => user.id !== userId));
          
            
          } catch (error) {
            console.error('Error deleting user:', error);
          }
          // Afficher une alerte de succès après la suppression (vous pouvez personnaliser cela)
          Swal.fire({
            title: "Supprimé !",
            text: "L'élément a été supprimé.",
            icon: "success",
            timer: 3000, // La modal de succès se fermera automatiquement après 3 secondes (ajustez selon vos besoins)
            timerProgressBar: true, // Afficher une barre de progression pour le compte à rebours
          });
        }
      });
       
      }
      const updateRessource = (e) => {
        e.preventDefault();
        //l'API modification ici
        console.log(newBlog);
        Swal.fire({
          title: "Erreur !",
          text: "Cette fonctionnalité n'est pas encore disponible",
          icon: "error",
          timer: 3000, // La modal de succès se fermera automatiquement après 3 secondes (ajustez selon vos besoins)
          timerProgressBar: true, // Afficher une barre de progression pour le compte à rebours
        });
      };

	return (
		<div className="w-100">
      <div className="contient">
        <button onClick={toggleAddBlog} className="ajout-ressource">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
        </svg>
        </button>
        <p>
          Ajouter une ressource 
        </p>
      </div>
               <Modal size="lg" isOpen={isOpenAddBlog}
        onRequestClose={toggleAddBlog} contentLabel="Ajouter Utilisateur "
        className="mymodal"
        ariaHideApp={false}
        overlayClassName="myoverlay"
        closeTimeoutMS={500} >          
 
               <form className="addForm" onSubmit={handleAddBlog}> 
                <div id="form-group"> 
                <label id="name-label"> Titre Ressource </label> 
                <input required  type="text" name="description" placeholder="Entrez le titre du blog" value={newBlog.description} onChange={(e) => setNewBlog({ ...newBlog, description: e.target.value })}/>
                  </div>
                  <div id="form-group"> 
                  <label  id="name-label"> Description Ressource </label> 
                <input className="textbox_input"  required type="text"  name="des"  placeholder="Description blog" value={newBlog.des} onChange={(e) => setNewBlog({ ...newBlog, des: e.target.value })}/>
                </div>
                <div id="form-group"> 
                <label  id="name-label"> Ressource </label> 
                <input  required type="file" accept=".doc,.pdf"  name="fileto" placeholder="Ressources .pdf ou .doc" onChange={(e) => setNewBlog({ ...newBlog, fileto: e.target.files[0] })}/>
                </div>
                <div id="form-group"> 
                <button type="submit"  >Ajouter</button>
                <button className='deletebutton' onClick={toggleAddBlog}>Annuler</button>
                </div>
                

            </form>
               </Modal>
                   {/*       MODIFICATION         */}
      <Modal
        size="lg"
        isOpen={isOpenUpdateBlog}
        onRequestClose={toggleUpdateBlog}
        contentLabel="Modifier utilisateur"
        className="mymodal"
        ariaHideApp={false}
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <form className="addForm" onSubmit={updateRessource}>
          <div id="form-group">
            <label id="name-label"> Titre Ressource </label>
            <input
              type="text"
              name="description"
              placeholder="Entrez le titre du blog"
              value={current.description}
              onChange={(e) =>
                setCurrent({ ...current, description: e.target.value })
              }
            />
          </div>
          <div id="form-group">
            <label id="name-label"> Description Ressource </label>
            <input
              className="textbox_input"
              required
              type="text"
              name="des"
              placeholder="Description blog"
              value={current.des}
              onChange={(e) => setCurrent({ ...current, des: e.target.value })}
            />
          </div>
          <div id="form-group">
            <label id="name-label"> Ressource </label>
            <input
              type="file"
              accept=".doc,.pdf"
              name="fileto"
              placeholder="Ressources .pdf ou .doc"
              onChange={(e) =>
                setCurrent({ ...current, fileto: e.target.files[0] })
              }
            />
          </div>
          <div id="form-group">
            <button type="submit">Modifier</button>
            <span  className="deletebutton_" onClick={toggleUpdateBlog}>
              Annuler
            </span >
          </div>
        </form>
      </Modal>
      <table style={{position:"relative",zIndex:"0"}} >
        <thead>
          <tr>
            <th>Id</th>
            <th colSpan={2}>Nom</th>
            <th>Description</th>
            <th>Télécharger</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {blog.map((myblog) => (
            <tr key={myblog.id}>
              <td className="td_width">{myblog.id}</td>
              <td colSpan={2} className="td_width">
                {myblog.des}
              </td>
              <td className="td_width">{myblog.description}</td>
              <td className="td_width">
                <button
                  onClick={() => DownloadRessource(myblog.fileto)}
                  type=""
                  class="btn btn-light"
                >
                  <img src={download} height={20} alt="download this file " />
                </button>
              </td>
              <td>
                <div className="boxflex">
                  <button className="editUser" type="button">
                    <img
                      height={18}
                      width={20}
                      alt=""
                      onClick={() => {
                        toggleUpdateBlog();
                        setCurrent(myblog);
                      }}
                      src={modifier}
                    />
                  </button>
                  <button
                    className="deleteUser"
                    onClick={() => handleDeleteUser(myblog.id)}
                    type="button"
                  >
                    <img height={18} width={20} alt="" src={supprimer} />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
		
	</div>
	);
};










