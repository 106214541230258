
import React from 'react';
import NavBarConnexion from "../NavBarConnexion";
// import UserTable from './UserTable';
import TableUsers from './TableUsers';
import Footer from '../Footer';

const GestionUser = () => {
 

  return (
    <>
       <NavBarConnexion/>
      <TableUsers/>
      <Footer />
      </>
  );

};

export default GestionUser;






 
