import React , {useState,useEffect} from 'react'
import Modal from "react-modal";
import "../../css/Table.css"
import axios from 'axios';
import CryptoJS from 'crypto-js';
import Swal from "sweetalert2";
const supprimer = require("../images/supprimer.png");
const modifier= require("../images/update.png");


const TableUsers =()=>{ 
  
    const [users,setUsers]=useState([])
    const [newUser, setNewUser] = useState({ username: "", password: "",  roleName:"" });
    const [editedUser, setEditedUser] = useState(null);
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);

  function toggleModal() {
    setIsOpenAdd(!isOpenAdd);
  }

  function toggleModalEdit() {
    setIsOpenEdit(!isOpenEdit);
  }
 function openEdit (current){ 
    setEditedUser(current);
    toggleModalEdit();

 }

    useEffect(() => {
        fetchUsers();
      }, []);
    
      const fetchUsers = async () => {
        try {
          const response = await fetch('https://precvadijoal-fadhiouth.sn/list-users'); 
          const data = await response.json();
          console.log(data);
          setUsers(data);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      };
    
      
      const handleCreateUser = async (e) => {
        e.preventDefault();
        //hasher le mot de passe
        newUser.password  = CryptoJS.SHA256(newUser.password).toString(CryptoJS.enc.Hex);
        try {
        
          const response = await axios.post('https://precvadijoal-fadhiouth.sn/create-user', newUser);
          console.log(response.data);
          fetchUsers();
        //   const createdUser = response.data;
        //   setUsers([...users, createdUser])
          setNewUser({ username: "", password:"",roleName:"" });
          setIsOpenAdd(!isOpenAdd);
        //   setIsSubmitted(true);
          // Handle successful login, e.g., store token in local storage or state
          Swal.fire({
            title: "Reussie !",
            text: "L'élément a été Ajouté.",
            icon: "success",
            timer: 3000, // La modal de succès se fermera automatiquement après 3 secondes (ajustez selon vos besoins)
            timerProgressBar: true, // Afficher une barre de progression pour le compte à rebours
          });
        } catch (error) {
          console.error(error);
        //   setErrorMessages({ name: "uname", message: errors.uname });`
          // Handle error, e.g., display error message to the user
        }
      };

      const handleDeleteUser =async(userId)=> { 
       

          Swal.fire({
            title: "Êtes-vous sûr de vouloir supprimer cet élément ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui, supprimer!",
            cancelButtonText: "Annuler",
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                await axios.delete(`https://precvadijoal-fadhiouth.sn/delete-user/${userId}`);
                setUsers(users.filter(user => user.id !== userId));
                
              } catch (error) {
                console.error('Error deleting user:', error);
              }
      
              // Afficher une alerte de succès après la suppression (vous pouvez personnaliser cela)
              Swal.fire({
                title: "Supprimé !",
                text: "L'élément a été supprimé.",
                icon: "success",
                timer: 3000, // La modal de succès se fermera automatiquement après 3 secondes (ajustez selon vos besoins)
                timerProgressBar: true, // Afficher une barre de progression pour le compte à rebours
              });
            }
          });
      }
        
      const handleUpdateUser = async (updatedUser)=>{ 
        //hasher le mot de passe
        updatedUser.password  = CryptoJS.SHA256(updatedUser.password).toString(CryptoJS.enc.Hex);
        try {
            const response = await axios.put(`https://precvadijoal-fadhiouth.sn/update-user/${updatedUser.id}`, updatedUser);
            const updatedUserData = response.data;
            setIsOpenEdit(!isOpenEdit);
            console.log(updatedUserData)
            fetchUsers();
            // setUsers(users.map(user => user.id === parseInt (updatedUserData.id ) ? updatedUserData : user));
            setEditedUser(null);
            Swal.fire({
              title: "Reussie !",
              text: "L'élément a été Modifié.",
              icon: "success",
              timer: 3000, // La modal de succès se fermera automatiquement après 3 secondes (ajustez selon vos besoins)
              timerProgressBar: true, // Afficher une barre de progression pour le compte à rebours
            });
          } catch (error) {
            console.error('Error updating user:', error);
          }
      
      }
      
      
     

    
    return ( 
      <div className="tableWrap">
      <div>
        <div className="contient">
        <button onClick={toggleModal} className="ajout-ressource">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
        </svg>
        </button>
        <p>
          Ajouter Utilisateur
        </p>
      </div>
               <Modal  isOpen={isOpenAdd}
        onRequestClose={toggleModal} contentLabel="Ajouter Utilisateur "
        className="mymodal"
        ariaHideApp={false}
        overlayClassName="myoverlay"
        closeTimeoutMS={500} >          
 
               <form className="addForm" onSubmit={handleCreateUser}> 
                <div id="form-group"> 
                <label id="name-label"> Username </label> 
                <input required  type="text" name="username" placeholder="Entrez le mail" value={newUser.username} onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}/>
                  </div>
                  <div id="form-group"> 
                  <label  id="name-label"> Password </label> 
                <input  required type="password"  name="password"  placeholder="mot de passe" value={newUser.password} onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}/>
                </div>
                {/* <input   type="text"  name="roleName" placeholder="role" value={newUser.roleName} onChange={(e) => setNewUser({ ...newUser, roleName: e.target.value })}/> */}
                <div id="form-group"> 
                <label  id="name-label"> Role </label> 
                <select  required value={newUser.roleName}
    onChange={(e) => setNewUser({ ...newUser, roleName: e.target.value })}> 
                  <option  value="" hidden>Selectionnez un Role</option>
                  <option value="super">SuperAdministrateur</option>
                 <option value="decideur">Decideur</option>
                 <option value="visiteur">Gestionnaire des données</option>
                </select>
                </div>
                <div id="form-group"> 
                <button type="submit"  >Ajouter</button>
                <button className='deletebutton' onClick={toggleModal}>Annuler</button>
                </div>
                

            </form>
               </Modal>
               <Modal  isOpen={isOpenEdit}
        onRequestClose={toggleModalEdit} contentLabel="Ajouter Utilisateur "
        className="mymodal"
        ariaHideApp={false}
        overlayClassName="myoverlay"
        closeTimeoutMS={500} > 
          {editedUser && (
                 <form onSubmit={e => {
                   e.preventDefault();
                   handleUpdateUser(editedUser);
                 }}>
                     <div id="form-group"> 
                <label  id="name-label"> Username </label> 
                   <input required
                     type="text"
                     value={editedUser.username}
                     onChange={(e) => setEditedUser({ ...editedUser, username: e.target.value })}
                   />
                   </div>
                   <div id="form-group"> 
                  <label  id="name-label"> Password </label> 
                   <input required
                     type="text"
                    /*value={editedUser.password}*/
                     onChange={(e) => setEditedUser({ ...editedUser, password: e.target.value })}
                   />
                   </div>
                   <div id="form-group"> 
                <label  id="name-label"> Role </label> 
                   <select  required  value={editedUser.roleId}
             onChange={(e) => setEditedUser({ ...editedUser, roleId: e.target.value })}> 
                           <option value="1">SuperAdministrateur</option>
                          <option value="2">Decideur</option>
                          <option value="3">Gestionnaire données</option>
                         </select>
                         </div>
               <div id="form-group"> 
                   <button type="submit">Modifier</button>
                   <span
                  className="deletebutton"
                  style={{
                    width: "50%",
                    height: "6vh",
                    outline: "1px solid black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor:"pointer",
                    backgroundColor:"#bb2d3b",
                    color:"white"
                  }}
                  onClick={toggleModalEdit}
                >
                  Annuler
                </span>
                   </div>
                 </form>
                
               )}

        </Modal>
               
           <table> 
        
            <thead style={{position:"relative",zIndex:"0"}} > 
                <tr> 
                <th> Id</th>
                <th> Nom Utilisateur</th>
                {/* <th> Mot de passe </th> */}
                <th> Role </th>
                <th>Actions </th>
                </tr>

            </thead>
            <tbody>
                {
                 users.map((current)=> ( 
                 <tr key={current.id}> 
                    <td> {current.id}</td>
                    <td>{current.username}</td>
                    {/* <td>{current.password}</td> */}
                     <td>{current.roleId === "1"  ? "Super Administrateur": current.roleId==="2" ? "Decideur" : "Gestionnaire des données"
                       
                     } </td>
                     <td> 
                        <button  className="editUser" onClick={() => openEdit(current) }>
                            <img  height={18} width={20} alt ="" src={modifier} />
                        </button>

                        <button onClick={() => handleDeleteUser(current.id)}  className="deleteUser"> 
                       
                        <img    height={18} width={20}  alt ="" src={supprimer} />
                        </button>
                     </td>
                 </tr>
                  ))
                 }
                  </tbody>
            </table>

          

            </div>
        </div>
    ) 
}

export default TableUsers; 




